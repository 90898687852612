// src/providers/config/ConfigProvider.jsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const ConfigContext = createContext(null);

export const useConfig = () => useContext(ConfigContext);

export function ConfigProvider({ children }) {
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await axios.get('/api/config/ui');
                const result = response.data;
                console.log('Configuration loaded:', result);
                setConfig(result);
            } catch (err) {
                setError(err);
                console.error('Failed to load configuration:', err);
            } finally {
                setLoading(false);
            }
        };

        return fetchConfig();
    }, []);

    if (loading) {
        return <div>Loading configuration...</div>;
    }

    if (error) {
        return <div>Error loading configuration</div>;
    }

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
}