import React from 'react';
import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";
import Footer from "./Common/Footer";

const Page = () => {
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                position: 'relative',
                overflow: 'auto', // Enable scrolling here
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    width: '100%',
                }}
            >
                <Outlet/>
            </Box>
            <Footer/>
        </Box>
    );
}

export default Page;