import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Page from "./Page";
import {MarketingPage} from "./Marketing/Page";
import {DashboardPage} from "./Dashboard/Page";
import {ResumePage} from "./Resume/Page";
import {SpringAIPage} from "./Spring-AI/Page";
import {DocumentsPage} from "./Documents/Page";
import {ConfigProvider, useConfig} from "./providers/config/ConfigProvider.jsx";
import {TokenProvider} from "./providers/token/TokenProvider.jsx";
import {Auth0Provider} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <Page/>,
                children: [
                    {
                        path: "/",
                        element: <MarketingPage/>,
                    },
                    {
                        path: "/dashboard",
                        element: <DashboardPage/>
                    },
                    {
                        path: "/resume",
                        element: <ResumePage/>
                    },
                    {
                        path: "/spring-ai",
                        element: <SpringAIPage/>
                    },
                    {
                        path: "/documents",
                        element: <DocumentsPage/>
                    }
                ],
            },
        ],
    },
]);

function AuthWrapper() {
    const config = useConfig();

    if (!config) {
        return <div>Loading...</div>;
    }

    return (
        <Auth0Provider
            domain={config.auth.domain}
            clientId={config.auth.clientId}
            useRefreshTokens={true}
            cacheLocation={"localstorage"}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: config.auth.audience,
                scope: config.auth.scope
            }}
        >
            <TokenProvider>
                <RouterProvider router={router} />
            </TokenProvider>
        </Auth0Provider>
    );
}

root.render(
    <React.StrictMode>
        <ConfigProvider>
            <AuthWrapper />
        </ConfigProvider>
    </React.StrictMode>
);