import React from 'react';
import './App.css';
import JrgAppBar from "./JrgAppBar";
import FloatingChatButton from "./Chat/FloatingChatButton";
import {Outlet} from "react-router-dom";
import {createTheme} from '@mui/material/styles';
import {Box, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#59484D',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#D9A282',
        },
        text: {
            primary: '#2C4A47', // Darker version of #B8D9D5
            secondary: '#59484D',
        },
        background: {
            default: '#59484D',
            paper: '#FFFFFF',
        },
        chat: {
            ai: '#2C4A47',
            user: '#FFFFFF',
            timestamp: {
                ai: '#666666',
                user: '#E0E0E0',
            }
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <JrgAppBar />
                <Box
                    component="main"
                    sx={{
                        flex: 1,
                        overflow: 'auto',
                        position: 'relative'
                    }}
                >
                    <Outlet />
                </Box>
                <FloatingChatButton />
            </Box>
        </ThemeProvider>
    );
}

export default App;