import React from 'react';

const AIPrimerSlides = () => (
    <div className="space-y-8">
        {/* ML Fundamentals Slide */}
        <div className="min-h-[600px] p-8 bg-gradient-to-br from-white to-gray-50">
            <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-green-500 to-blue-500 text-transparent bg-clip-text">
                Machine Learning Fundamentals
            </h2>
            <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4 bg-white p-6 rounded-xl shadow-lg">
                    <h3 className="text-xl font-semibold text-indigo-600">Core Concepts</h3>
                    <ul className="space-y-3">
                        {[
                            'Pattern Recognition in Data',
                            'Training vs. Inference',
                            'Model Types & Architecture',
                            'Supervised & Unsupervised Learning'
                        ].map((item, index) => (
                            <li key={index} className="flex items-center">
                                <div className="w-2 h-2 bg-indigo-400 rounded-full mr-3"></div>
                                <span className="text-gray-700">{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="bg-gradient-to-br from-indigo-100 to-blue-100 rounded-xl shadow-lg p-1">
                    <div className="bg-white rounded-lg h-full p-6">
                        <h3 className="text-xl font-semibold text-blue-600 mb-4">Key Components</h3>
                        <ul className="space-y-3">
                            {[
                                'Data Preprocessing',
                                'Feature Engineering',
                                'Model Selection',
                                'Evaluation Metrics'
                            ].map((item, index) => (
                                <li key={index} className="flex items-center">
                                    <div className="w-2 h-2 bg-blue-400 rounded-full mr-3"></div>
                                    <span className="text-gray-700">{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        {/* NLP & AI Models Slide */}
        <div className="min-h-[600px] p-8 bg-gradient-to-br from-white to-gray-50">
            <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-green-500 to-blue-500 text-transparent bg-clip-text">
                Natural Language Processing
            </h2>
            <div className="grid grid-cols-2 gap-6">
                <div className="space-y-4 bg-white p-6 rounded-xl shadow-lg">
                    <h3 className="text-xl font-semibold text-indigo-600">NLP Capabilities</h3>
                    <ul className="space-y-3">
                        {[
                            'Text Understanding & Analysis',
                            'Language Generation',
                            'Sentiment Analysis',
                            'Named Entity Recognition',
                            'Question Answering'
                        ].map((item, index) => (
                            <li key={index} className="flex items-center">
                                <div className="w-2 h-2 bg-indigo-400 rounded-full mr-3"></div>
                                <span className="text-gray-700">{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="space-y-4 bg-white p-6 rounded-xl shadow-lg">
                    <h3 className="text-xl font-semibold text-blue-600">Popular Models</h3>
                    <ul className="space-y-3">
                        {[
                            'GPT-3.5 & GPT-4',
                            'Claude & Claude 2',
                            'PaLM & Gemini',
                            'BERT & RoBERTa',
                            'Fine-tuned Models'
                        ].map((item, index) => (
                            <li key={index} className="flex items-center">
                                <div className="w-2 h-2 bg-blue-400 rounded-full mr-3"></div>
                                <span className="text-gray-700">{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export {AIPrimerSlides};