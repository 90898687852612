import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';
import { Box, Slider, IconButton, styled } from '@mui/material';

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.primary.main,
    height: 4,
    padding: 0,
    '& .MuiSlider-thumb': {
        width: 8,
        height: 8,
        transition: '0.3s',
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}33`,
        },
        '&.Mui-active': {
            width: 12,
            height: 12,
        },
    },
    '& .MuiSlider-rail': {
        opacity: 0.28,
    },
}));

const AudioController = ({ audio }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    useEffect(() => {
        if (!audio) return;

        const handlePlay = () => setIsPlaying(true);
        const handlePause = () => setIsPlaying(false);
        const handleEnded = () => {
            setIsPlaying(false);
            setProgress(0);
        };

        const handleTimeUpdate = () => {
            const percentage = (audio.currentTime / audio.duration) * 100;
            setProgress(percentage);
        };

        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('timeupdate', handleTimeUpdate);

        // Automatically play the audio when it is ready
        audio.play().catch(console.error);

        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [audio]);

    const handleProgressChange = (event, newValue) => {
        if (!audio) return;
        const time = (newValue / 100) * audio.duration;
        audio.currentTime = time;
        setProgress(newValue);
    };

    const togglePlay = () => {
        if (!audio) return;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play().catch(console.error);
        }
    };

    const toggleMute = () => {
        if (!audio) return;
        audio.muted = !audio.muted;
        setIsMuted(!isMuted);
    };

    if (!audio) return null;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'transparent',
            padding: 1,
            borderRadius: 1
        }}>
            <IconButton
                onClick={togglePlay}
                size="small"
                sx={{
                    color: 'text.primary',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}
            >
                {isPlaying ?
                    <Pause size={18} /> :
                    <Play size={18} />
                }
            </IconButton>

            <IconButton
                onClick={toggleMute}
                size="small"
                sx={{
                    color: 'text.primary',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                }}
            >
                {isMuted ?
                    <VolumeX size={18} /> :
                    <Volume2 size={18} />
                }
            </IconButton>

            <StyledSlider
                value={progress}
                onChange={handleProgressChange}
                sx={{ mx: 1, flex: 1 }}
            />
        </Box>
    );
};

export default AudioController;