import React from 'react';
import {StyledFormArea} from './StyledFormArea';
import {Presentation} from "./Presentation";

const SpringAIPage = () => {
    return (
        <React.Fragment>
            <StyledFormArea>
                <h1>Welcome to the Spring AI Page</h1>
                <p>This is a basic website hosted on the Spring AI page.</p>

                {/* Embedded Presentation */}
                <div className="my-8 w-full">
                    <Presentation defaultHeight="600px"/>
                </div>
            </StyledFormArea>
        </React.Fragment>
    );
};
export {SpringAIPage};