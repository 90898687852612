import React, { useState } from 'react';
import { MessageSquareMore, X } from 'lucide-react';
import { Box } from '@mui/material';
import SimpleChatInterface from './SimpleChatInterface';

const BUTTON_SIZE = 48; // button width/height
const BUTTON_PADDING = 16; // button padding

const FloatingChatButton = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(false);

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '24px',
                right: '24px',
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
            }}
        >
            {isChatOpen && <SimpleChatInterface onClose={() => setIsChatOpen(false)} />}
            <button
                onClick={() => setIsVisible(false)}
                style={{
                    marginBottom: '8px',
                    padding: '4px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                aria-label="Close chat widget"
            >
                <X className="h-5 w-5 text-gray-600" />
            </button>
            <button
                style={{
                    backgroundColor: 'white',
                    padding: `${BUTTON_PADDING}px`,
                    borderRadius: '50%',
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    transition: 'box-shadow 0.3s ease',
                    width: `${BUTTON_SIZE}px`,
                    height: `${BUTTON_SIZE}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => setIsChatOpen(!isChatOpen)}
                onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 6px 8px rgba(0,0,0,0.2)'}
                onMouseOut={(e) => e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)'}
                aria-label="Toggle chat interface"
            >
                <MessageSquareMore className="h-6 w-6 text-blue-500" />
            </button>
        </Box>
    );
};

export default FloatingChatButton;