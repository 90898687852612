import React, {useCallback, useState} from 'react';
import {Maximize2, Minimize2} from 'lucide-react';
// import TitleAndIntroSlides from './TitleAndIntroSlides';
import {AIPrimerSlides} from './components/Slides.jsx';

const Presentation = ({defaultHeight = "600px"}) => {
    const [currentSection, setCurrentSection] = useState(0);
    const [currentSlideInSection, setCurrentSlideInSection] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);

    // Define all sections
    const sections = [
        // {
        //     component: TitleAndIntroSlides,
        //     slideCount: 2 // Number of slides in this section
        // },
        {
            component: AIPrimerSlides,
            slideCount: 2
        }
        // Add other sections here as they're created
    ];

    const currentSlide = sections[currentSection].component;

    const toggleFullscreen = useCallback(() => {
        if (!isFullscreen) {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    }, [isFullscreen]);

    const nextSlide = () => {
        if (currentSlideInSection < sections[currentSection].slideCount - 1) {
            setCurrentSlideInSection(prev => prev + 1);
        } else if (currentSection < sections.length - 1) {
            setCurrentSection(prev => prev + 1);
            setCurrentSlideInSection(0);
        }
    };

    const prevSlide = () => {
        if (currentSlideInSection > 0) {
            setCurrentSlideInSection(prev => prev - 1);
        } else if (currentSection > 0) {
            setCurrentSection(prev => prev - 1);
            setCurrentSlideInSection(sections[currentSection - 1].slideCount - 1);
        }
    };

    const totalSlides = sections.reduce((acc, section) => acc + section.slideCount, 0);
    const currentSlideNumber = sections.slice(0, currentSection).reduce((acc, section) => acc + section.slideCount, 0) + currentSlideInSection + 1;

    const CurrentSlideComponent = sections[currentSection].component;

    return (
        <div className="w-full">
            <div
                className={`relative ${isFullscreen ? 'fixed inset-0 z-50 bg-white' : 'rounded-xl overflow-hidden shadow-lg'}`}
                style={{height: isFullscreen ? '100vh' : defaultHeight}}
            >
                {/* Current Slide Content */}
                <div className="absolute inset-0 bg-white overflow-auto">
                    <CurrentSlideComponent currentSlide={currentSlideInSection}/>
                </div>

                {/* Navigation Controls */}
                <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center gap-4 z-10">
                    <button
                        onClick={prevSlide}
                        className="px-4 py-2 bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-lg hover:opacity-90 disabled:opacity-50"
                        disabled={currentSection === 0 && currentSlideInSection === 0}
                    >
                        Previous
                    </button>
                    <span className="text-gray-500">
            {currentSlideNumber} / {totalSlides}
          </span>
                    <button
                        onClick={nextSlide}
                        className="px-4 py-2 bg-gradient-to-r from-green-500 to-blue-500 text-white rounded-lg hover:opacity-90 disabled:opacity-50"
                        disabled={currentSection === sections.length - 1 && currentSlideInSection === sections[currentSection].slideCount - 1}
                    >
                        Next
                    </button>
                </div>

                {/* Fullscreen Toggle */}
                <button
                    onClick={toggleFullscreen}
                    className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors z-10"
                >
                    {isFullscreen ? (
                        <Minimize2 className="w-5 h-5 text-gray-600"/>
                    ) : (
                        <Maximize2 className="w-5 h-5 text-gray-600"/>
                    )}
                </button>
            </div>
        </div>
    );
};

export {Presentation};