import { useState, useEffect } from 'react';

export const useChat = () => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [audioElements, setAudioElements] = useState({});
    const [hasAudioPermission, setHasAudioPermission] = useState(null);

    useEffect(() => {
        // Check audio permission
        navigator.mediaDevices?.getUserMedia({ audio: true })
            .then(() => setHasAudioPermission(true))
            .catch(() => setHasAudioPermission(false));
    }, []);

    const sendMessage = async (message) => {
        if (!message.trim()) return;

        const messageId = Date.now().toString();
        const userMessage = { type: 'user', content: message, timestamp: new Date() };
        setMessages(msgs => [...msgs, userMessage]);
        setIsLoading(true);

        try {
            const textResponse = await fetch(
                `/openai?message=${encodeURIComponent(message)}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            const responseText = await textResponse.text();

            // Only handle audio if we have permission
            if (hasAudioPermission) {
                try {
                    const audio = new Audio();
                    const audioResponse = await fetch(
                        `/openai/stream?message=${encodeURIComponent(responseText)}`,
                        { method: 'GET' }
                    );

                    if (audioResponse.ok) {
                        const mediaSource = new MediaSource();
                        audio.src = URL.createObjectURL(mediaSource);

                        mediaSource.addEventListener('sourceopen', async () => {
                            const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                            const reader = audioResponse.body.getReader();
                            const chunks = [];

                            while (true) {
                                const { done, value } = await reader.read();
                                if (done) break;
                                chunks.push(value);
                            }

                            for (const chunk of chunks) {
                                await new Promise((resolve) => {
                                    if (!sourceBuffer.updating) {
                                        sourceBuffer.appendBuffer(chunk);
                                        sourceBuffer.addEventListener('updateend', resolve, { once: true });
                                    } else {
                                        sourceBuffer.addEventListener('updateend', () => {
                                            sourceBuffer.appendBuffer(chunk);
                                            sourceBuffer.addEventListener('updateend', resolve, { once: true });
                                        }, { once: true });
                                    }
                                });
                            }

                            mediaSource.endOfStream();
                        });

                        setAudioElements(prev => ({
                            ...prev,
                            [messageId]: audio
                        }));
                    }
                } catch (audioError) {
                    console.warn('Audio failed, continuing without audio:', audioError);
                }
            }

            setMessages(msgs => [...msgs, {
                type: 'ai',
                content: responseText,
                timestamp: new Date(),
                messageId,
                hasAudio: hasAudioPermission
            }]);

            return true;
        } catch (error) {
            console.error('Error:', error);
            setMessages(msgs => [...msgs, {
                type: 'error',
                content: 'Error: Failed to get response',
                timestamp: new Date(),
            }]);
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        messages,
        isLoading,
        sendMessage,
        audioElements,
        hasAudioPermission
    };
};