import {Box, IconButton, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import {styled} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import React from "react";

const FooterBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: '10vh',
    maxHeight: '50vh',
    padding: '24px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        minHeight: 'auto',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
    },
}));

function Footer() {
    const theme = useTheme();
    return (
        <FooterBox>
            <Box sx={{
                display: "flex",
                gap: 2,
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                    width: '100%',
                }
            }}>
                <IconButton
                    size="large"
                    color={theme.palette.primary.contrastText}
                    href="https://www.linkedin.com/in/jackie-gleason-software-engineer/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            padding: '12px',
                        }
                    }}
                >
                    <FontAwesomeIcon
                        size="lg"
                        color={theme.palette.primary.contrastText}
                        icon={faLinkedinIn}/>
                </IconButton>
                <IconButton
                    size="large"
                    href="https://github.com/jrgleason"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            padding: '12px',
                        }
                    }}
                >
                    <FontAwesomeIcon
                        size="2x"
                        color={theme.palette.primary.contrastText}
                        icon={faGithub}/>
                </IconButton>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down('sm')]: {
                    alignItems: 'center',
                }
            }}>
                <Box>
                    <Typography></Typography>
                </Box>
            </Box>
        </FooterBox>
    )
}

export default Footer;