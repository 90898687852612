import {Box} from "@mui/material";
import {styled} from "@mui/system";

const StyledFormArea = styled(Box)(({theme}) => ({
    minHeight: `calc(100vh - 25vh)`, // Assuming the footer height is 25vh
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
}));

export {StyledFormArea};