import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Slider,
    Snackbar,
    Switch,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {useAuth0} from "@auth0/auth0-react";

export function DocumentsPage() {
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [rawText, setRawText] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [inputTab, setInputTab] = useState(0);

    // Text splitting settings
    const [useCustomSettings, setUseCustomSettings] = useState(false);
    const [defaultChunkSize, setDefaultChunkSize] = useState(800);
    const [minChunkSizeChars, setMinChunkSizeChars] = useState(350);
    const [minChunkLengthToEmbed, setMinChunkLengthToEmbed] = useState(5);
    const [maxNumChunks, setMaxNumChunks] = useState(10000);
    const [keepSeparator, setKeepSeparator] = useState(true);
    const [chunkOverlap, setChunkOverlap] = useState(0);
    const [chunksCreated, setChunksCreated] = useState(null);

    const canAddDocuments = true;

    useEffect(() => {
        // Fetch documents when component mounts
        if (isAuthenticated) {
            fetchDocuments();
        }
    }, [isAuthenticated]);

    const fetchDocuments = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch('/pinecone/search', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch documents');
            }

            const data = await response.json();
            setDocuments(data);
        } catch (error) {
            console.error('Error fetching documents:', error);
            setError('Failed to load documents. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleTabChange = (event, newValue) => {
        setInputTab(newValue);
    };

    const handleUpload = async () => {
        if (!canAddDocuments) {
            setError('You do not have permission to add documents.');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const token = await getAccessTokenSilently();

            if (inputTab === 0 && selectedFile) {
                // Upload file
                const formData = new FormData();
                formData.append('file', selectedFile);

                if (useCustomSettings) {
                    formData.append('useCustomSettings', 'true');
                    formData.append('defaultChunkSize', defaultChunkSize);
                    formData.append('minChunkSizeChars', minChunkSizeChars);
                    formData.append('minChunkLengthToEmbed', minChunkLengthToEmbed);
                    formData.append('maxNumChunks', maxNumChunks);
                    formData.append('keepSeparator', keepSeparator);
                    formData.append('chunkOverlap', chunkOverlap);
                }

                const response = await fetch('/pinecone/add/file', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to upload file');
                }

                const result = await response.json();
                setChunksCreated(result.chunks);
                setSuccessMessage(`File uploaded successfully. Created ${result.chunks} chunks.`);
                setSelectedFile(null);
            } else if (inputTab === 1 && rawText.trim()) {
                // Upload raw text
                const payload = {
                    content: rawText,
                    metadata: {
                        type: 'text',
                        source: 'manual_input',
                        timestamp: new Date().toISOString()
                    }
                };

                if (useCustomSettings) {
                    payload.useCustomSettings = true;
                    payload.defaultChunkSize = defaultChunkSize;
                    payload.minChunkSizeChars = minChunkSizeChars;
                    payload.minChunkLengthToEmbed = minChunkLengthToEmbed;
                    payload.maxNumChunks = maxNumChunks;
                    payload.keepSeparator = keepSeparator;
                    payload.chunkOverlap = chunkOverlap;
                }

                const response = await fetch('/document/add', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to add text');
                }

                const result = await response.json();
                setChunksCreated(result.chunks);
                setSuccessMessage(`Text added successfully. Created ${result.chunks} chunks.`);
                setRawText('');
            } else {
                throw new Error('Please select a file or enter text');
            }

            // Refresh the document list
            fetchDocuments();
        } catch (error) {
            console.error('Error uploading document:', error);
            setError(error.message || 'Failed to upload document. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (documentId) => {
        if (!canAddDocuments) {
            setError('You do not have permission to delete documents.');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const token = await getAccessTokenSilently();
            
            // Get the document ID from the metadata
            const documentToDelete = documents[documentId];
            
            // Check if the document has an ID in its metadata
            if (!documentToDelete.metadata || !documentToDelete.metadata.id) {
                throw new Error('Document ID not found in metadata');
            }
            
            const id = documentToDelete.metadata.id;
            
            console.log(`Deleting document with ID: ${id}`);
            
            const response = await fetch(`/pinecone/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete document');
            }

            // Remove the document from the local state
            const updatedDocuments = [...documents];
            updatedDocuments.splice(documentId, 1);
            setDocuments(updatedDocuments);
            
            setSuccessMessage('Document deleted successfully');
            
            // Refresh the documents list to ensure UI is in sync with backend
            fetchDocuments();
        } catch (error) {
            console.error('Error deleting document:', error);
            setError(error.message || 'Failed to delete document. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async () => {
        if (!searchQuery.trim()) {
            fetchDocuments();
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`/document/search?query=${encodeURIComponent(searchQuery)}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to search documents');
            }

            const data = await response.json();
            setDocuments(data);
        } catch (error) {
            console.error('Error searching documents:', error);
            setError('Failed to search documents. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSuccessMessage(null);
    };

    return (
        <Box sx={{p: 3}}>
            <Typography variant="h4" gutterBottom>
                Document Management
            </Typography>

            {error && (
                <Alert severity="error" sx={{mb: 2}}>
                    {error}
                </Alert>
            )}

            <Paper sx={{p: 2, mb: 3}}>
                <Typography variant="h6" gutterBottom>
                    Add Document
                </Typography>

                {!canAddDocuments && (
                    <Alert severity="warning" sx={{mb: 2}}>
                        You don't have permission to add documents. Please contact an administrator.
                    </Alert>
                )}

                <Tabs value={inputTab} onChange={handleTabChange} sx={{mb: 2}}>
                    <Tab label="Upload File"/>
                    <Tab label="Raw Text"/>
                </Tabs>

                {inputTab === 0 && (
                    <Box sx={{mb: 2}}>
                        <Button
                            variant="contained"
                            component="label"
                            disabled={!canAddDocuments}
                        >
                            Select File
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                        {selectedFile && (
                            <Typography variant="body2" sx={{mt: 1}}>
                                Selected: {selectedFile.name}
                            </Typography>
                        )}
                    </Box>
                )}

                {inputTab === 1 && (
                    <Box sx={{mb: 2}}>
                        <TextareaAutosize
                            minRows={5}
                            placeholder="Enter text content..."
                            value={rawText}
                            onChange={(e) => setRawText(e.target.value)}
                            disabled={!canAddDocuments}
                            style={{width: '100%', padding: '8px'}}
                        />
                    </Box>
                )}

                <FormControlLabel
                    control={
                        <Switch
                            checked={useCustomSettings}
                            onChange={(e) => setUseCustomSettings(e.target.checked)}
                            disabled={!canAddDocuments}
                        />
                    }
                    label="Use Custom Settings"
                />

                {useCustomSettings && (
                    <Box sx={{mt: 2}}>
                        <Typography variant="subtitle1" gutterBottom>
                            Text Splitting Settings
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body2">Default Chunk Size</Typography>
                                <Slider
                                    value={defaultChunkSize}
                                    onChange={(e, newValue) => setDefaultChunkSize(newValue)}
                                    min={100}
                                    max={2000}
                                    step={100}
                                    disabled={!canAddDocuments}
                                />
                                <Typography variant="caption">{defaultChunkSize} tokens</Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body2">Min Chunk Size (chars)</Typography>
                                <Slider
                                    value={minChunkSizeChars}
                                    onChange={(e, newValue) => setMinChunkSizeChars(newValue)}
                                    min={50}
                                    max={1000}
                                    step={50}
                                    disabled={!canAddDocuments}
                                />
                                <Typography variant="caption">{minChunkSizeChars} characters</Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body2">Min Chunk Length to Embed</Typography>
                                <Slider
                                    value={minChunkLengthToEmbed}
                                    onChange={(e, newValue) => setMinChunkLengthToEmbed(newValue)}
                                    min={1}
                                    max={20}
                                    step={1}
                                    disabled={!canAddDocuments}
                                />
                                <Typography variant="caption">{minChunkLengthToEmbed} tokens</Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body2">Max Number of Chunks</Typography>
                                <Slider
                                    value={maxNumChunks}
                                    onChange={(e, newValue) => setMaxNumChunks(newValue)}
                                    min={1000}
                                    max={50000}
                                    step={1000}
                                    disabled={!canAddDocuments}
                                />
                                <Typography variant="caption">{maxNumChunks} chunks</Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={keepSeparator}
                                            onChange={(e) => setKeepSeparator(e.target.checked)}
                                            disabled={!canAddDocuments}
                                        />
                                    }
                                    label="Keep Separator"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body2">Chunk Overlap</Typography>
                                <Slider
                                    value={chunkOverlap}
                                    onChange={(e, newValue) => setChunkOverlap(newValue)}
                                    min={0}
                                    max={100}
                                    step={5}
                                    disabled={!canAddDocuments}
                                />
                                <Typography variant="caption">{chunkOverlap} tokens</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box sx={{mt: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={isLoading || !canAddDocuments || (inputTab === 0 && !selectedFile) || (inputTab === 1 && !rawText.trim())}
                    >
                        {isLoading ? <CircularProgress size={24}/> : 'Upload'}
                    </Button>
                </Box>
            </Paper>

            <Paper sx={{p: 2, mb: 3}}>
                <Typography variant="h6" gutterBottom>
                    Search Documents
                </Typography>

                <Box sx={{display: 'flex', mb: 2}}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter search query..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{mr: 1}}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        disabled={isLoading}
                    >
                        <SearchIcon/>
                    </Button>
                </Box>
            </Paper>

            <Paper>
                <Typography variant="h6" gutterBottom sx={{p: 2}}>
                    Your Documents
                </Typography>
                {isLoading ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', p: 3}}>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <List>
                        {documents.map((doc, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleDelete(index)}
                                        disabled={isLoading}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={doc.metadata?.name || `Document ${index + 1}`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography component="span" variant="body2" color="text.primary">
                                                {doc.metadata?.type || 'Unknown type'}
                                            </Typography>
                                            {` — ID: ${doc.metadata?.id || 'Unknown ID'} — `}
                                            {`${doc.content.substring(0, 100)}${doc.content.length > 100 ? '...' : ''}`}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        ))}
                        {documents.length === 0 && (
                            <ListItem>
                                <ListItemText primary="No documents found"/>
                            </ListItem>
                        )}
                    </List>
                )}
            </Paper>

            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={successMessage}
            />
        </Box>
    );
} 