import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Bot, ImageIcon, Sparkles } from 'lucide-react';
import AudioController from './AudioController';

const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(2),
    '&.user': {
        justifyContent: 'flex-end'
    },
    '&.ai': {
        justifyContent: 'flex-start'
    }
}));

const MessageBubble = styled(Box)(({ theme, variant }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    maxWidth: '80%',
    ...(variant === 'user' && {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF' // Ensuring white text for user messages
    }),
    ...(variant === 'error' && {
        backgroundColor: '#FEE2E2', // Light red background
        color: '#DC2626' // Dark red text for errors
    }),
    ...(variant === 'ai' && {
        backgroundColor: '#F5F5F5', // Lighter background for better contrast
        color: '#2C4A47' // Darker version of your teal for better readability
    })
}));

const AILabel = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    color: '#666666', // Darker color for AI label
    fontSize: theme.typography.pxToRem(12)
}));

const TimeStamp = styled(Typography)(({ theme, variant }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: variant === 'user'
        ? 'rgba(255,255,255,0.8)' // Slightly more opaque for better readability
        : '#666666', // Darker color for AI timestamps
    marginTop: theme.spacing(0.5)
}));

const MessageImage = styled('img')({
    borderRadius: 8,
    maxWidth: '100%',
    height: 'auto'
});

const EnhancedMessageBubble = ({ message, audio }) => {
    const isUser = message.type === 'user';
    const isError = message.type === 'error';
    const isImage = message.mode === 'openai-image' && message.type === 'ai';

    const getAILabel = (mode) => {
        switch (mode) {
            case 'openai-chat':
                return 'OpenAI';
            case 'openai-image':
                return 'DALL·E';
            case 'anthropic':
                return 'Claude';
            default:
                return 'AI';
        }
    };

    const getAIIcon = (mode) => {
        switch (mode) {
            case 'openai-chat':
                return <Sparkles size={12} />;
            case 'openai-image':
                return <ImageIcon size={12} />;
            case 'anthropic':
                return <Bot size={12} />;
            default:
                return null;
        }
    };

    const isBase64 = (str) => {
        try {
            return str.startsWith('data:image') || btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    const getFormattedTime = (timestamp) => {
        try {
            const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
            return date.toLocaleTimeString();
        } catch (err) {
            console.error('Error formatting timestamp:', err);
            return '';
        }
    };

    return (
        <MessageContainer className={isUser ? 'user' : 'ai'}>
            <MessageBubble variant={isUser ? 'user' : isError ? 'error' : 'ai'}>
                {!isUser && !isError && (
                    <AILabel>
                        {getAIIcon(message.mode)}
                        {getAILabel(message.mode)}
                    </AILabel>
                )}

                {isImage ? (
                    <MessageImage
                        src={isBase64(message.content)
                            ? `data:image/png;base64,${message.content}`
                            : message.content}
                        alt="Generated"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M12 4v16m-8-8h16"/></svg>';
                            e.target.style.backgroundColor = '#eee';
                            e.target.style.padding = '1rem';
                            e.target.nextSibling.textContent = 'Error loading image';
                        }}
                    />
                ) : (
                    <Box sx={{ whiteSpace: 'pre-wrap' }}>{message.content}</Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {!isUser && message.hasAudio && audio && (
                        <Box sx={{ mt: 2, mb: 1 }}>
                            <AudioController audio={audio} />
                        </Box>
                    )}

                    <TimeStamp variant={isUser ? 'user' : 'ai'}>
                        {getFormattedTime(message.timestamp)}
                    </TimeStamp>
                </Box>
            </MessageBubble>
        </MessageContainer>
    );
};

export default EnhancedMessageBubble;