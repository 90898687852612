import React, { useState } from 'react';
import { Box, Paper, TextField, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';
import { X, Send } from 'lucide-react';
import { useChat } from './Hooks/useChat';
import MessageBubble from "./MessageBubble";
import { LoadingSpinner } from "./LoadingSpinner";

const SimpleChatInterface = ({ onClose }) => {
    const { messages, isLoading, sendMessage, audioElements } = useChat();
    const [input, setInput] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isShortScreen = useMediaQuery('(max-height: 500px)');
    const shouldBeFullScreen = isMobile || isShortScreen;

    const handleSubmit = async (e) => {
        e?.preventDefault();
        if (!input.trim() || isLoading) return;
        try {
            const success = await sendMessage(input);
            if (success) setInput('');
        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            void handleSubmit();
        }
    };

    const CHAT_WIDTH = 300;
    const CHAT_HEIGHT = 400;

    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                overflow: 'hidden',
                ...(shouldBeFullScreen ? {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    zIndex: theme.zIndex.modal
                } : {
                    position: 'absolute',
                    bottom: '48px', // Align with top of button
                    right: 'calc(100% + 8px)',
                    width: CHAT_WIDTH,
                    height: CHAT_HEIGHT,
                    borderRadius: 2,
                    zIndex: theme.zIndex.modal
                })
            }}
        >
            <Box sx={{
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: theme.palette.primary.main,
                color: 'white',
            }}>
                <Typography variant="subtitle1">
                    {shouldBeFullScreen ? 'Chat Assistant' : 'Chat'}
                </Typography>
                <IconButton size="small" onClick={onClose} sx={{ color: 'white' }}>
                    <X size={18} />
                </IconButton>
            </Box>

            <Box sx={{
                flex: 1,
                overflowY: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}>
                {messages.length === 0 ? (
                    <div className="text-gray-400 italic text-center mt-4">
                        {'Start a conversation...'}
                    </div>
                ) : (
                    <div className="space-y-4">
                        {messages.map((msg, index) => (
                            <MessageBubble
                                key={index}
                                message={msg}
                                audio={msg.hasAudio ? audioElements[msg.messageId] : null}
                            />
                        ))}
                        {isLoading && (
                            <div className="flex justify-center">
                                <LoadingSpinner />
                            </div>
                        )}
                    </div>
                )}
            </Box>

            <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider', bgcolor: 'white' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Type a message..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={isLoading}
                        multiline
                        maxRows={4}
                    />
                    <IconButton
                        onClick={() => void handleSubmit()}
                        disabled={isLoading || !input.trim()}
                        color="primary"
                    >
                        <Send size={20} />
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
};

export default SimpleChatInterface;