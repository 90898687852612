import {AppBar, Box, Button, Drawer, IconButton, Toolbar, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHome} from "@fortawesome/free-solid-svg-icons";
import {faGithub} from "@fortawesome/free-brands-svg-icons";
import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

function JrgAppBar() {
    const [open, setOpen] = useState(false);
    const {loginWithRedirect, logout, isAuthenticated, isLoading} = useAuth0();
    const theme = useTheme();
    if (isLoading) {
        return <div>Loading ...</div>;
    }
    return (
        <React.Fragment>
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(140, 20, 40, 0.85)",
                    color: "#c0c5c9",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    top: 0,
                    '& .MuiButton-root, & .MuiIconButton-root, & .MuiTypography-root': {
                        color: "#c0c5c9"
                    }
                }}
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        color="inherit"
                        component={Link}
                        to="/"
                        sx={{mr: 2}}
                    >
                        <FontAwesomeIcon icon={faHome}/>
                    </IconButton>

                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            width: '14vw',
                            '@media (max-width:650px)': {
                                display: 'none',
                            }
                        }}></Box>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                textShadow: "1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000"
                            }}>
                            The Jackie Gleason
                        </Typography>
                    </Box>

                    <Button
                        size="large"
                        sx={{mr: 2}}
                        href={"https://docs.google.com/document/d/1xrqve90hloOYGR3RSCvwXJBXq_NQR5kmNfrxKYSveKk/edit?usp=sharing"}
                        color="inherit">Résumé</Button>

                    {isAuthenticated && (
                        <Button
                            size="large"
                            sx={{mr: 2}}
                            component={Link}
                            to="/documents"
                            color="inherit">Documents</Button>
                    )}

                    <IconButton
                        size="large"
                        color="inherit"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://github.com/jrgleason"}
                        sx={{mr: 1}}
                    >
                        <FontAwesomeIcon icon={faGithub}/>
                    </IconButton>

                    <IconButton
                        size="large"
                        color="inherit"
                        target="_top"
                        rel="noopener noreferrer"
                        href={`mailto:jackiegleason+jrgsite@gmail.com`}
                        sx={{mr: 1}}
                    >
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </IconButton>

                    {isAuthenticated ? (
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={
                                () =>
                                    logout({
                                        logoutParams: {
                                            returnTo: window.location.origin
                                        }
                                    })
                            }
                            sx={{
                                borderColor: "#c0c5c9",
                                '&:hover': {
                                    backgroundColor: 'rgba(192, 197, 201, 0.1)'
                                }
                            }}
                        >
                            Logout
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={() => loginWithRedirect()}
                            sx={{
                                borderColor: "#c0c5c9",
                                '&:hover': {
                                    backgroundColor: 'rgba(192, 197, 201, 0.1)'
                                }
                            }}
                        >
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
                <Typography
                    variant="h5"
                    component="div">
                    Hey Old Timer!
                </Typography>
            </Drawer>
        </React.Fragment>
    )
}

export default JrgAppBar;